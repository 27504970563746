import MPPContainer from '../mpp-container/container';
import config from '../configuration/configuration';
import { getGenericMPPModalTemplate, getSeamlessApplyBuyCookie, generateGALinkerId } from '../Util/CommonUtils';
import services from '../../../src/services/Services';
class OfferIntegartion extends MPPContainer {
    constructor(analytics) {
        super(analytics);
        this.registerOfferEventListener();
        this.windowObject = window;
    }
    openMPPModal = (optionalArgs) => {
        let modalElementId = config[config.env].syfMppModalHolderId;
        //check if modal id does not exist
        if (!document.getElementById(modalElementId)) {
            let modalDiv = document.createElement("div");
            modalDiv.id = modalElementId;
            document.body.appendChild(modalDiv);
        }
        let modalTemplate = getGenericMPPModalTemplate(optionalArgs['partnerId'], optionalArgs);
        this.loadModalInDOM(modalElementId, modalTemplate);
        this.modalActionOnDOMAttach(optionalArgs);
        if (document.getElementsByClassName('syf-mpp-chng-height')) {
            if ((window.parent.innerHeight - 20) < 700) {
                document.getElementsByClassName('syf-mpp-chng-height')[0].style.height = window.innerHeight + 'px';
            } else {
                document.getElementsByClassName('syf-mpp-chng-height')[0].style.height = window.parent.innerHeight - 20 + 'px';
            }
        }

    }
    modalActionOnDOMAttach = (optionalArgs) => {
        getSeamlessApplyBuyCookie(optionalArgs.partnerId, (syfToken) => {
            this.flowType = optionalArgs.flowType;
            this.partnerId = optionalArgs.partnerId;
            this.syf_Token = syfToken;
            if (!window._SYF_token) window._SYF_token = syfToken;
            let childSyfPartnerId = optionalArgs.childSyfPartnerId;
            let partnerCode = optionalArgs.partnerCode;
            let childSyfMerchantNumber = optionalArgs.childSyfMerchantNumber;
            let promoOverRideFlag = optionalArgs.promoOverRideFlag;
            let offerNumber = optionalArgs.offerNumber;
            let additionalParams = "";
            let childMid = optionalArgs.childMid;
            let partnerProfileCode = optionalArgs.partnerProfileCode;
            let merchantRefererUrl = optionalArgs.merchantRefererUrl;
            let processInd = optionalArgs?.processInd;


            if (childSyfPartnerId && childSyfPartnerId != "undefined") {
                additionalParams += "&childSyfPartnerId=" + childSyfPartnerId;
            }
            if (partnerCode && partnerCode != "undefined") {
                additionalParams += "&partnerCode=" + partnerCode;
            }
            if (childSyfMerchantNumber && childSyfMerchantNumber != "undefined") {
                additionalParams += "&childSyfMerchantNumber=" + childSyfMerchantNumber;
            }
            if (offerNumber && offerNumber != "undefined") {
                additionalParams += "&offerNumber1=" + offerNumber;
            }
            if (partnerProfileCode) additionalParams += "&partnerProfileCode=" + partnerProfileCode;
            if (merchantRefererUrl) additionalParams += "&merchantRefererUrl=" + merchantRefererUrl;
            if (processInd) additionalParams += "&processInd=" + processInd;

            let modalURL = config[config.env].syfMPPModalURL['pdp'];
            let productCategoryArgs = (optionalArgs['productCategoryNames'] !== null && optionalArgs['productCategoryNames'] !== undefined) ? optionalArgs['productCategoryNames'].toString() : "";

            modalURL = modalURL + "?partnerId=" + optionalArgs['partnerId'] + "&amount=" + optionalArgs['amount'] + "&syfToken=" + syfToken + "&productCategoryNames=" + productCategoryArgs + "&flowType=" + optionalArgs['flowType'] + "&cid=" + optionalArgs['cid'] + "&mppFromAnyWhereInputFlag=" + optionalArgs['mppFromAnyWhereInputFlag'] + additionalParams;

            let destinationURLWithVisitorIDs = modalURL;
            try {
                var visitor = window.Visitor.getInstance('22602B6956FAB4777F000101@AdobeOrg');
                destinationURLWithVisitorIDs = visitor.appendVisitorIDsTo(modalURL);

            } catch (exception) {
                console.log("error creation analytics object");
            }
            let offerModelURLWithAdobe_GATracker = generateGALinkerId(destinationURLWithVisitorIDs)
            document.getElementById('mppFrame').src = offerModelURLWithAdobe_GATracker;
        });

    }
    registerOfferEventListener() {
        window.onresize = function () {
            let parentHeight = window.parent.innerHeight - 20;
            let parentWidth = window.parent.innerWidth;
            if (parentWidth <= 767 && window.innerHeight < parentHeight) {
                window.innerHeight = parentHeight;
            }
            if ((window.innerHeight - 20) < 700) {
                document.getElementsByClassName('syf-mpp-chng-height')[0].style.height = window.innerHeight + 'px';
            } else {
                document.getElementsByClassName('syf-mpp-chng-height')[0].style.height = window.innerHeight - 20 + 'px';
            }
        }

    }
    closeModal = () => {
        this.closeModalWindow();
    }
}
export default OfferIntegartion;
